section.blog-posts {
  margin-top: 2rem;
  padding: 0 2rem;
}

div.blog-post-container {
  display: grid;
  gap: 2rem;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 4rem;
}

h1.blog-post {
  font-size: clamp(var(--m-big-font-title), 5vw, var(--d-big-font-title));
  text-align: center;
  line-height: clamp(var(--big-line-height), 3.5vw, var(--large-line-height));
}

@media (min-width: 768px) {
  section.blog-posts {
    padding: 0 3rem;
  }
}

@media (min-width: 1000px) {
  div.blog-post-container {
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  }
}

/* aside.loader {
  min-height: 50rem;
} */

section.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
}

svg.loader {
  width: clamp(3rem, 3vw, 5.4rem);
  height: clamp(2.5rem, 2.5vw, 4.9rem);
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p.loader {
  font-size: clamp(1.5rem, 1.5vw, 2rem);
  font-weight: 600;
  line-height: 1.7rem;
  letter-spacing: -0.40799999237060547px;
  margin-top: 1.2rem;
  color: #407bff;
}

p.loader span {
  opacity: 0;
  transition: opacity, var(--fast-transition) ease-in;
  animation: fade-in 3s ease-in-out infinite;
  animation-delay: calc(var(--dot) * 700ms);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

section.contact-hero {
  height: 100vh;
  max-height: 45.1rem;
  /* max-height: 33.1rem; */
  background-image: url("https://img.freepik.com/free-photo/carefree-happy-good-looking-emotive-african-american-girl-with-curly-hair-looking-up-gesturing-smiling-while-using-cellphone_176420-23233.jpg?t=st=1717716858~exp=1717720458~hmac=694fdab912ec054f80c1283e3a6291010c1bde7ea818c23deb84fb41ba695559&w=740");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1111;
  background-blend-mode: overlay;
  display: grid;
  place-items: center;
}

h1.contact-hero-title {
  font-size: clamp(var(--m-big-font-title), 5vw, var(--d-big-font-title));
  line-height: clamp(var(--big-line-height), 3.5vw, var(--large-line-height));
  color: #fff;
  text-align: center;
  text-shadow: 0 0.3rem 0.5rem rgb(0, 0, 0, 0.5);
}

section.event {
  padding: 0 4rem;
  margin-top: 4rem;
}

h2.event {
  text-align: center;
  font-size: clamp(
    var(--m-medium-font-title),
    2.2vw,
    var(--d-medium-font-title)
  );
  line-height: clamp(var(--big-line-height), 3.5vw, var(--ex-big-line-height));
}

div.event-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: clamp(2rem, 3vw, 3rem);
  margin-top: 2rem;
}

img.event-img {
  width: 100%;
  max-height: 51rem;
  border-radius: 0.4rem;
}

article.event {
  display: grid;
  gap: 2rem;
}

h3.event {
  font-size: clamp(var(--m-small-font-title), 1.8vw, var(--d-small-font-title));
  line-height: clamp(
    var(--small-line-height),
    2.4vw,
    var(--ex-big-line-height)
  );
}

p.event {
  font-size: clamp(var(--m-text-size), 1.6vw, var(--d-small-text-size));
  line-height: clamp(var(--small-line-height), 1.95vw, var(--big-line-height));
}

ul.event-list {
  display: grid;
  gap: 1rem;
}

li.event-list-item {
  font-size: clamp(var(--m-text-size), 1.6vw, var(--d-small-text-size));
  line-height: clamp(var(--small-line-height), 1.95vw, var(--big-line-height));
}

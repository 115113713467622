aside.join-us {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: clamp(3rem, 4vw, 4rem);
}

h2.join-us {
  font-size: clamp(
    var(--m-medium-font-title),
    2.2vw,
    var(--d-medium-font-title)
  );
  line-height: clamp(var(--big-line-height), 3.5vw, var(--ex-big-line-height));
}

p.join-us {
  font-size: clamp(var(--m-text-size), 1.6vw, var(--d-small-text-size));
  margin-top: 1rem;
  line-height: clamp(var(--small-line-height), 1.95vw, var(--big-line-height));
}

a.join-us {
  font-size: clamp(0.8rem, 1.8vw, var(--d-small-text-size));
  line-height: clamp(
    var(--small-line-height),
    2.4vw,
    var(--ex-big-line-height)
  );
  line-height: clamp(
    var(--ex-small-line-height),
    1.95vw,
    var(--big-line-height)
  );
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  color: white;
  background-color: #407bff;
  width: fit-content;
  margin-top: clamp(0.4rem, 4vw, 1rem);
}

footer.footer {
  background-color: black;
  color: #fff;
  padding: 2rem;
  margin-top: 2rem;
}

section.footer {
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.6fr 1fr;
}

nav.footer {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

ul.footer-list {
  display: grid;
  gap: 1rem;
}

h2.footer {
  font-size: clamp(
    var(--m-medium-font-title),
    2.2vw,
    var(--d-medium-font-title)
  );
  line-height: clamp(var(--big-line-height), 3.5vw, var(--ex-big-line-height));
}

footer.footer li a {
  color: #fff;
  font-size: clamp(var(--m-small-text-size), 1.4vw, var(--d-small-text-size));
  line-height: clamp(
    var(--ex-small-line-height),
    1.95vw,
    var(--big-line-height)
  );
}

footer.footer p {
  margin: 4rem;
  font-size: clamp(var(--m-text-size), 1.6vw, var(--d-small-text-size));
  line-height: clamp(
    var(--ex-small-line-height),
    1.95vw,
    var(--big-line-height)
  );
}

section.post-body {
  width: 90%;
  display: flex;
  margin: 0 auto;
  margin-top: clamp(2rem, 4vw, 4rem);
}

h1.post-title {
  font-size: clamp(var(--m-big-font-title), 5vw, var(--d-big-font-title));
  line-height: clamp(var(--big-line-height), 3.5vw, var(--large-line-height));
}

h2.post-subheading {
  font-size: clamp(
    var(--m-medium-font-title),
    2.2vw,
    var(--d-medium-font-title)
  );
  line-height: clamp(var(--big-line-height), 3.5vw, var(--ex-big-line-height));
  margin-top: clamp(1rem, 1vw, 2rem);
}

p.post-text {
  font-size: clamp(var(--m-text-size), 1.6vw, var(--d-small-text-size));
  margin-top: clamp(0.5rem, 0.5vw, 1rem);
  line-height: clamp(var(--small-line-height), 2.95vw, var(--big-line-height));
}

ul.post-list {
  display: grid;
  gap: 0.5rem;
  padding: 0 clamp(1rem, 3vw, 2rem);
}

h3.post-list-title {
  font-size: clamp(var(--m-small-font-title), 1.8vw, var(--d-small-font-title));
  line-height: clamp(
    var(--small-line-height),
    2.4vw,
    var(--ex-big-line-height)
  );
}

p.post-list-description {
  font-size: clamp(var(--m-small-text-size), 1.4vw, var(--d-small-text-size));
  line-height: clamp(var(--small-line-height), 2.95vw, var(--big-line-height));
}

footer.post-body-author-info {
  margin-top: 3rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

footer.post-body-author-info div {
  display: flex;
  align-items: center;
}

img.post-body-author {
  width: clamp(3rem, 3vw, 8rem);
  height: clamp(3rem, 3vw, 8rem);
  border-radius: 50%;
}

h4.past-body-author-name {
  margin-left: 0.5rem;
  font-size: clamp(1.2rem, 1.8vw, var(--d-small-font-title));
  line-height: clamp(
    var(--small-line-height),
    2.4vw,
    var(--ex-big-line-height)
  );
  line-height: clamp(
    var(--ex-small-line-height),
    1.95vw,
    var(--big-line-height)
  );
}

time.post-body {
  font-size: clamp(1rem, 1.8vw, var(--d-small-font-title));
  line-height: clamp(
    var(--small-line-height),
    2.4vw,
    var(--ex-big-line-height)
  );
  line-height: clamp(
    var(--ex-small-line-height),
    1.95vw,
    var(--big-line-height)
  );
}

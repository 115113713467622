main.not-found-page {
  height: 100vh;
  max-height: 50rem;
  display: grid;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}

main.not-found-page center {
  display: grid;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}

h1.not-found-page {
  font-size: clamp(var(--m-big-font-title), 5vw, var(--d-big-font-title));
  text-align: center;
  line-height: clamp(var(--big-line-height), 3.5vw, var(--large-line-height));
}

p.not-found-page {
  font-size: clamp(var(--m-text-size), 1.6vw, var(--d-small-text-size));
  line-height: clamp(var(--small-line-height), 1.95vw, var(--big-line-height));
  margin-top: 1rem;
}

button.not-found-page {
  font-size: clamp(var(--m-small-text-size), 1.4vw, var(--d-small-text-size));
  line-height: clamp(
    var(--ex-small-line-height),
    1.95vw,
    var(--big-line-height)
  );
  padding: 1rem;
  border-radius: 0.8rem;
  color: white;
  background-color: #407bff;
  width: fit-content;
  justify-self: center;
}

section.recent-post {
  margin-top: clamp(6rem, 8vw, 8rem);
  padding: 0rem 2rem;
  display: grid;
  gap: 4rem;
}

h2.recent-post {
  text-align: center;
  font-size: clamp(
    var(--m-medium-font-title),
    2.2vw,
    var(--d-medium-font-title)
  );
  line-height: clamp(var(--big-line-height), 3.5vw, var(--ex-big-line-height));
  position: relative;
  /* width: 15rem; */
}

h2.recent-post::before {
  content: "";
  position: absolute;
  left: -0.8rem;
  right: -0.8rem;
  bottom: -1rem;
  height: 0.1rem;
  background-color: #111;
}

section.recent-post-content {
  display: grid;
  gap: 2rem;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  /* padding: 0 3rem; */
}

@media (min-width: 768px) {
  section.recent-post-content {
    padding: 0 3rem;
  }
}

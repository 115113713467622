section.about-hero {
  height: 100vh;
  max-height: 45.1rem;
  /* max-height: 33.1rem; */
  background-image: url("https://img.freepik.com/free-photo/business-hands-joined-together-teamwork_53876-30575.jpg?t=st=1717716296~exp=1717719896~hmac=04709a06ca60c777a82a8a021f054926a0c5b0b058da926852e9e3183a5bc426&w=740");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1111;
  background-blend-mode: overlay;
  display: grid;
  place-items: center;
}

h1.about-hero-title {
  text-align: center;
  color: #fff;
  text-shadow: 0 0.3rem 0.5rem rgb(0, 0, 0, 0.5);
  font-size: clamp(var(--m-big-font-title), 5vw, var(--d-big-font-title));
  line-height: clamp(var(--big-line-height), 3.5vw, var(--large-line-height));
  padding: 2rem;
}

section.post-page-hero {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: clamp(2rem, 3vw, 3rem);
}

img.post-page-hero {
  width: 100%;
  height: clamp(33.1rem, 33vw, 51.1rem);
  object-position: center;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem rgb(0, 0, 0, 0.1);
  object-fit: cover;
}

section.post-page-hero > section {
  margin-top: clamp(1rem, 2vw, 2rem);
  background-color: #333;
  color: #fff;
  border-radius: 0.5rem;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
}

img.is-interview {
  /* float: left; */
  /* width: 50%; */
  /* max-width: 40rem; */
  /* min-width: 30rem; */
  height: clamp(33.1rem, 33vw, 51.1rem);
  max-width: 51.1rem;
  object-position: center;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem rgb(0, 0, 0, 0.1);
  /* margin-right: 2rem; */
  width: 100%;
}

h1.interview {
  font-size: clamp(var(--m-big-font-title), 5vw, var(--d-big-font-title));
  line-height: clamp(4rem, 4vw, var(--large-line-height));
  white-space: pre-warp;
  word-wrap: break-word;
  /* word-break: break-all; */
  text-align: center;
}

p.interview {
  font-size: clamp(1.2rem, 1.2vw, var(--m-small-text-size));
  margin-top: clamp(1rem, 1vw, 1.8rem);
  line-height: clamp(
    var(--ex-small-line-height),
    1.95vw,
    var(--small-line-height)
  );
  width: 95%;
  display: flex;
  margin: 1rem auto;
}

@media (min-width: 768px) {
  section.post-page-hero > section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 3rem;
    align-items: center;
    background-color: #333;
    color: #fff;
  }

  p.interview {
    margin: 0 auto;
  }
}
